import React, { createElement } from 'react'
import { useSelector } from 'react-redux'
import { MenuItemLink, getResources, WithPermissions } from 'react-admin'
import TankIcon from '@material-ui/icons/Gradient'
import DistributorIcon from '@material-ui/icons/LocalShipping'
import InventoryIcon from '@material-ui/icons/List'
import LocationIcon from '@material-ui/icons/PinDrop'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AdminIcon from '@material-ui/icons/Dvr'
import { Box, useMediaQuery } from '@material-ui/core'
import SubMenu from './subMenu'

const Menu = (props) => {
	const { onMenuClick, logout } = props

	const [dashboard, setDashboard] = React.useState(true)
	const [admin, setAdmin] = React.useState(true)
	const [reports, setReports] = React.useState(true)

	const open = useSelector((state) => state.admin.ui.sidebarOpen)
	const resources = useSelector((state) => getResources(state))
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

	const toggleDashboard = () => {
		setDashboard((prev) => !prev)
	}

	const toggleAdmin = () => {
		setAdmin((prev) => !prev)
	}

	const toggleReports = () => {
		setReports((prev) => !prev)
	}

	return (
		<Box mt={3}>
			<WithPermissions
				render={({ permissions }) => {
					if (!permissions) {
						return null
					}
					const { dashboardAccess, role } = permissions

					return (
						<div>
							{dashboardAccess !== undefined &&
								(dashboardAccess.includes('distributor') ||
									dashboardAccess.includes('location') ||
									dashboardAccess.includes('tank')) && (
									<SubMenu
										handleToggle={toggleDashboard}
										isOpen={dashboard}
										sidebarIsOpen={open}
										name='Dashboard'
										icon={<DashboardIcon />}>
										{dashboardAccess.includes('distributor') && (
											<MenuItemLink
												key='dashboard/distributor'
												to='/dashboard/distributor'
												primaryText='Distributor'
												leftIcon={<DistributorIcon />}
												sidebarIsOpen={open}
												onClick={onMenuClick}
											/>
										)}
										{dashboardAccess.includes('location') && (
											<MenuItemLink
												key='dashboard/location'
												to='/dashboard/location'
												primaryText='Location'
												leftIcon={<LocationIcon />}
												sidebarIsOpen={open}
												onClick={onMenuClick}
											/>
										)}
										{dashboardAccess.includes('tank') && (
											<MenuItemLink
												key='dashboard/tank'
												to='/dashboard/tank'
												primaryText='Tank'
												leftIcon={<TankIcon />}
												sidebarIsOpen={open}
												onClick={onMenuClick}
											/>
										)}
									</SubMenu>
								)}
							{(role === 'Root' || role === 'Admin') && (
								<SubMenu
									handleToggle={toggleAdmin}
									isOpen={admin}
									sidebarIsOpen={open}
									name='Admin'
									icon={<AdminIcon />}>
									{resources
										.filter(
											(resource) => resource.hasList && resource.options.admin
										)
										.map(
											(resource) =>
												resource.name !== 'inventory' && (
													<MenuItemLink
														key={resource.name}
														to={`/${resource.name}`}
														primaryText={resource.options.label}
														leftIcon={createElement(resource.icon)}
														sidebarIsOpen={open}
														onClick={onMenuClick}
													/>
												)
										)}
								</SubMenu>
							)}

							<SubMenu
								handleToggle={toggleReports}
								isOpen={reports}
								sidebarIsOpen={open}
								name='Reports'
								icon={<DashboardIcon />}>
								<MenuItemLink
									key='reports/inventory'
									to='/reports/inventory'
									primaryText='Inventory'
									leftIcon={<InventoryIcon />}
									sidebarIsOpen={open}
									onClick={onMenuClick}
								/>
							</SubMenu>

							{isSmall && logout}
						</div>
					)
				}}
			/>
		</Box>
	)
}

export default Menu